import apiClient from '@/services/axios'

const getAllSurvey = async () => {
  const res = await apiClient.get('/survey/question/all')
  if (res) {
    return res
  } else {
    return []
  }
}
const getSurveyByProgram = async (id) => {
  const res = await apiClient.get('/survey/question?programTypeId=' + id)
  if (res) {
    return res
  } else {
    return []
  }
}
const createQuestionSurvey = async (payload) => {
  const res = await apiClient.post('/survey/question', payload)
  if (res) {
    return res
  } else {
    return null
  }
}
const createAnswerQuestionSurvey = async (payload) => {
  const res = await apiClient.post('/survey/answer', payload)
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  getAllSurvey,
  getSurveyByProgram,
  createQuestionSurvey,
  createAnswerQuestionSurvey,
}
