<template>
  <div>
    <div
      style="z-index: 99"
      class="d-inline-block float-left mt-3 mt-sm-0 mb-3 mb-sm-3"
    >
      <!-- <label class="float-left text-left"
        ><strong> Filter tanggal redemption </strong></label
      >
      <br /> -->
      <a-select
        v-model="program"
        style="
          width: 180px;
          z-index: 99;
          border: 1px solid black;
          border-radius: 4px;
        "
        placeholder="Pilih Program"
        @change="handleFilterProgram"
      >
        <a-select-option value="all"> Pilih Semua </a-select-option>
        <a-select-option
          v-for="(i, index) in programTypeItems"
          :key="index"
          :value="i.id"
        >
          {{ i.name }}
        </a-select-option>
      </a-select>
    </div>
    <div
      style="z-index: 99"
      class="d-inline-block float-right ml-0 ml-sm-2 mt-3 mt-sm-0 mb-3 mb-sm-3"
    >
      <!-- <label class="float-left text-left"
        ><strong> Filter tanggal redemption </strong></label
      >
      <br /> -->
      <a-button @click="$router.push('/survey/jawaban/tambah')">
        + Tambah Jawaban
      </a-button>
    </div>
    <div
      style="z-index: 99"
      class="d-inline-block float-right ml-0 ml-sm-2 mt-3 mt-sm-0 mb-3 mb-sm-3"
    >
      <!-- <label class="float-left text-left"
        ><strong> Filter tanggal redemption </strong></label
      >
      <br /> -->
      <a-button @click="$router.push('/survey/pertanyaan/tambah')">
        + Tambah Pertanyaan
      </a-button>
    </div>
    <a-divider></a-divider>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="itemsFiltered"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
        :expandRowByClick="true"
      >
        <template slot="name" slot-scope="name, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/obat/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.name }}</router-link
              >
            </div>
            <div v-if="!isAdmin">{{ record.name }}</div>
            <!-- <div class="text-gray-4">{{record.tag? record.tag: '-'}}</div> -->
          </div>
        </template>
        <!-- <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>{{ createdAt? moment(createdAt).format('DD MMM YYYY') : "-" }}</div>
          </div>
        </template> -->
        <template slot="status" slot-scope="status">
          <div>
            <div>{{ status ? 'Aktif' : 'Tidak Aktif' }}</div>
          </div>
        </template>
        <template slot="description" slot-scope="description">
          <div>
            <div>{{ description ? description : '-' }}</div>
          </div>
        </template>
        <template slot="expandedRowRender" slot-scope="record">
          <div v-if="record.responses.length">
            <div
              v-for="(res, index) in record.responses"
              :key="index"
              class="d-flex align-items-center"
              style="gap: 10px"
            >
              <label for="" class="m-0">-</label>
              <p class="m-0">{{ res.answer }}</p>
            </div>
          </div>
          <a-button
            v-if="record.responses.length < 3"
            type="link"
            @click="
              $router.push(
                `/survey/jawaban/tambah?programTypeId=${record.programType.id}&questionId=${record.id}`,
              )
            "
            >+ Tambah Jawaban</a-button
          >
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
// import { getAll } from '@/services/axios/api/obat'
import { getProgramTypeAll } from '@/services/axios/api/program'
import { getAllSurvey } from '@/services/axios/api/survey'
import moment from 'moment'
const columns = [
  {
    title: 'Pertanyaan Survey',
    dataIndex: 'question',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'question' },
    sorter: (a, b) => {
      return a.question.localeCompare(b.question)
    },
  },
  {
    title: 'Program',
    dataIndex: 'programType.name',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'program' },
    sorter: (a, b) => {
      return a.programType.name.localeCompare(b.programType.name)
    },
  },
  {
    title: 'Question ID',
    dataIndex: 'code',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'code' },
    sorter: (a, b) => {
      return a.code.localeCompare(b.code)
    },
  },
]

export default {
  name: 'TabelObat',
  data: function () {
    return {
      columns,
      items: [],
      itemsFiltered: [],
      isLoading: true,
      isAdmin: false,
      program: 'Pilih Program',
      programTypeItems: [],
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const res = await getProgramTypeAll()
      const resSurvey = await getAllSurvey()
      console.log(resSurvey.data, '<<<<')
      this.isLoading = false
      this.items = resSurvey.data
      this.itemsFiltered = resSurvey.data
      this.programTypeItems = res.data
    },
    async handleFilterProgram(value) {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        if (value === 'all') {
          this.itemsFiltered = this.items
        } else {
          this.itemsFiltered = this.items.filter(
            (item) => item.programType.id === value,
          )
        }
      }, 1000)
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
:global(.ant-table-expand-icon-th) {
  background-color: transparent !important;
}
</style>
